import { createRouter, createWebHistory } from 'vue-router';
import CountryPage from './pages/CountryPage.vue';
import ProjectsPage from './pages/ProjectsPage.vue';
import HauryPage from './pages/HauryPage.vue';
import MapHome from './pages/MapHome.vue'; // Correct import path

const routes = [
  {
    path: '/CARP/map',
    name: 'MapHome',
    component: MapHome,
    meta: { collection: 'carpProjectsTest' }
  },
  {
    path: '/CARP/:country',
    name: 'CountryPage',
    component: CountryPage,
    props: true,
    meta: { collection: 'carpProjectsTest' }
  },
  {
    path: '/CARP/projects',
    name: 'ProjectsPage',
    component: ProjectsPage,
    meta: { collection: 'carpProjectsTest' }
  },
  {
    path: '/haury/native-pathways',
    redirect: '/haury/resilience-graduate-research-awards'
  },
  {
    path: '/haury/resilience-graduate-research-awards',
    name: 'HauryPage',
    component: HauryPage,
    props: true,
    meta: { collection: 'haury' }
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
