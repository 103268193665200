<!--
Author: Lily McMullen
Description: This page displays a map of projects in a specific country, 
             combining the CountryMap and ProjectList components to show geographical 
             locations of projects and their details.
-->

<template>
  <div class="container">
    <header class="row">
      <div class="col col-12 text-center">
        <h1 class="my-2"><strong>{{ formattedCountry }}</strong></h1>
        <p>{{ projectCount }} {{ projectLabel }}</p>
        <router-link to="/CARP/map" class="btn btn-blue btn-sm mb-3">Back to Map</router-link>
      </div>
    </header>
    <div class="row">
      <div class="col col-12">
        <CountryMap ref="countryMap" :country="country" :projects="projects" @highlight="(e) => highlightProject = e" />    
      </div>
      <div class="col col-12">
        <ProjectList :projects="projects" :highlight="highlightProject" />    
      </div>
    </div>
  </div>
</template>

<script>
import CountryMap from '../components/CountryMap.vue';
import ProjectList from '../components/ProjectList.vue';

export default {
  components: {
    CountryMap,
    ProjectList
  },
  props: ['country', 'projectData'],
  data() {
    return {
      projects: [], // array to store project data
      projectCount: 0, // count of projects loaded
      highlightProject: null
    };
  },
  computed: {
    projectLabel() {
      return this.projectCount === 1 ? 'project' : 'projects'; // check for multiple projects (plural title?)
    },
    formattedCountry() {
      var splitCountry = this.country.split(' ');
      var formattedWords = [];
      for(let word of splitCountry){
        formattedWords.push(word.charAt(0).toUpperCase() + word.slice(1));
      }
      return formattedWords.join(' ');
    }
  },
  methods: {
    async getProjects(){
      this.projects = [];
      const formattedCountry = this.formattedCountry;
      try {
        this.projectData[this.$route.meta.collection].getFilteredData([['country', 'array-contains', formattedCountry]]).then((query) => {
          if(query.success){
            this.projects = query.data;
          }
          this.projectCount = Object.keys(this.projects).length;
          // Emit event to the CountryMap component to add markers
          this.$refs.countryMap.initializeMap();
          this.$refs.countryMap.addMarkers(this.projects);
        }).catch((e) => {
          console.error(e);
        });

      } catch (error) {
        console.error('Error fetching project data:', error);
      }
    }
  },
  watch: {
    country() {
      this.highlightProject = null;
      this.getProjects();
    }
  },
  mounted() {
    this.getProjects();
  },
};
</script>
