<script setup>
import { defineProps, ref, watch, defineEmits } from 'vue';

/**
 * Props the component uses.
 * 
 * - `title`: Title to display for About popup.
 * - `projects`: List of all projects.
 * - `modalOpen`: Boolean to represent if another modal is open within the parent.
 */
const props = defineProps(['title', 'projects', 'modalOpen']);
/**
 * Emits the component uses.
 * - `showProject(id)`: Displays the provided ID in a HauryProjectModal.
 * - `centerMap(id)`: Centers the HauryMap on provided ID.
 */
const emits = defineEmits(['showProject', 'centerMap']);


/**
 * Local version of projects (allowing it to be sorted).
 * 
 * Use this for display to end user.
 */
const projectList = ref(props.projects);

/**
 * Object of all projects, sorted by year.
 * 
 * `{ year: [projects], }`
 * 
 * Use this for display to end user when sorted by year.
 */
const projectsByYear = ref({})

// watch props to see if parent changes anything, if so
// re-set the projectList ref and re-calculate projectsByYear
watch(props, async (newProps) => {
    projectList.value = newProps.projects;
    projectsByYear.value = sortProjectsByYear(newProps.projects);
});

/**
 * Sorts provided `projects` into an object with years as keys
 * and accompanying lists of projects as values.
 * @param projects List of projects to sort.
 * @returns Object
 */
function sortProjectsByYear(projects){
    var returnObj = {}
    for(let project of projects){
        if(!returnObj[project.Year]){
            //no year item in obj yet, initialize
            returnObj[project.Year] = [];
        }
        returnObj[project.Year].push(project);
    }
    return returnObj;
}


/**
 * How the project list is sorted.
 */
 const sortBy = ref('Year');
/**
 * Adjusts the sort of the displated project list based on `sortBy` ref.
 */
function changeSort(){
    if(sortBy.value == 'Year'){
        projectsByYear.value = sortProjectsByYear(projectList.value);
    } else {
        projectList.value.sort((a, b) => {
            return a[sortBy.value].localeCompare(b[sortBy.value]);
        })
    }
}
// watches whether sortBy is changed, and if so changes the sort.
watch(sortBy, async () => {
    changeSort();
});



/**
 * Boolean to show/hide the full project list modal.
 */
 const show = ref(false);
/**
 * Boolean to show/hide the about modal.
 */
const fullShow = ref(true);
/**
 * Toggles the `show` ref to show/hide the project list modal.
 */
function showHideModal(){
    if(show.value){
        show.value = false;
    } else {
        show.value = true;
    }
}

</script>

<template>
    <div class="map-info p-4 bg-none">
        <button class="btn btn-blue" @click="() => fullShow = true">About</button>
    </div>
    <div class="sidebar-container" v-if="!show && fullShow && !modalOpen">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="h3 p-0 m-0">{{ title }}</h2>
                    <button type="button" class="close" aria-label="Close" @click="() => fullShow = false">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col col-12">
                            <p>Resilience Graduate Research Awards Program (formerly known as Native Pathways Graduate Research Awards Program) is designed and administered by the Haury Program to strengthen the academic pathways for Resilience students and scholars, with an emphasis on University of Arizona programs and people devoted to tribal resilience education, research, and outreach.</p>
                            <p>These awards support University of Arizona graduate students doing research relevant to water, energy, and/or food issues and challenges, and other adverse consequences of extreme weather events faced by federally-recognized tribes, especially those within Arizona. </p>
                            <p>These awards are a part of a larger commitment to Tribal Resilience by the Haury Program started by the Haury Donor-Advised Fund Board in 2020.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-outline-blue" @click="showHideModal">View All Awards</button>
                    <button class="btn btn-blue" @click="() => fullShow = false">Explore Map</button>
                </div>
            </div>
        </div>
    </div>
    <div class="sidebar-container" v-if="show && !modalOpen">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="h3 p-0 m-0">List of Awards</h2>
                    <button type="button" class="close" aria-label="Close" @click="showHideModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header">
                    <div class="form-inline">
                        <label class="my-1 mr-2" for="sortBy">Sort By</label>
                        <select @change="(e) => sortBy = e.target.value" class="custom-select" id="sortBy" :value="sortBy">
                            <option value="Year">Year</option>
                            <option value="Title">Title</option>
                        </select>
                    </div>

                </div>
                <div class="modal-body">
                    <div class="row mb-2" v-for="project in projectList" v-bind:key="project.id" v-show="sortBy != 'Year'">
                        <div class="col col-12 col-lg-9"><p>{{ project.Title }} ({{ project.Year }})</p></div>
                        <div class="col col-12 col-lg-3"><p><button class="btn btn-outline-red" @click="emits('showProject', [project.id]); emits('centerMap', project.id)">View Project</button></p></div>
                    </div>
                    <div v-for="year in Object.keys(projectsByYear).sort().reverse()" v-bind:key="year" v-show="sortBy == 'Year'">
                        <div class="row mb-2">
                            <div class="col col-12"><h3 class="h4 mt-0">{{ year }}</h3></div>
                        </div>
                        <div class="row mb-2" v-for="project in projectsByYear[year]" v-bind:key="project.id">
                            <div class="col col-12 col-lg-9"><p>{{ project.Title }}</p></div>
                            <div class="col col-12 col-lg-3"><p><button class="btn btn-outline-red" @click="emits('showProject', [project.id]); emits('centerMap', project.id)">View Project</button></p></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="showHideModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.sidebar-container {
    z-index: 1000;
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
}

.map-info {
    z-index: 800;
    position: fixed;
    top: 0;
    right: 0;
    max-width: 600px;
}
</style>
