import { getFirestore, collection, getDocs } from "firebase/firestore";

import { app } from "./firebaseConfig";

/**
 * Queries Firestore for all projects housed within a collection
 * and allows filtering/retrieval of the data for use with Vue app.
 */
class Projects {
    db = null;
    data = [];
    collectionPulled = false;

    constructor(collection){
        // retrieve Firestore
        this.db = getFirestore(app);
        // query collection for data
        this.getTable(collection).then((response) => {
            // check if retrieval was successful,
            // output error if not.
            if(!response.success){
                console.error(response.error);
            }   
            // this.data is already set to an empty array,
            // so we can still consider "data" pulled
            this.collectionPulled = true;
        }).catch((e) => {
            // same as above comment, but if an actual error is
            // caught we want to do the same
            this.collectionPulled = true;
            console.error(e);
        });

    }

    /**
     * Returns the firestore object for backwards compatibility to
     * already existing firebase functions.
     * @returns Firestore object
     */
    getDB(){
        return this.db;
    }

    /**
     * Queries Firestore and returns the provided collection.
     * @param {String} table 
     * @returns {Promise}
     */
    async getTable(table){
        return new Promise((resolve, reject) => {
            if(this.collectionPulled){
                resolve({ success: true, data: this.data, message: 'Successfully retrieved collection from cache.' });
            }
            const q = collection(this.db, table);
            getDocs(q).then((snapshot) => {
                this.data = [];
                if(!snapshot.empty){
                    snapshot.forEach((doc) => {
                        this.data.push({id: doc.id, ...doc.data()});
                    })
                    resolve({ success: true, data: this.data, message: 'Successfully retrieved collection.' });
                } else {
                    resolve({ success: false, data: [], message: 'Collection is empty.' })
                }
            }).catch((e) => {
                reject({ success: false, error: e, message: 'An error occurred while attempting to query collection.' });
            });
        })
    }

    /**
     * Returns collection data after ensuring constructor is done retrieving it.
     * @param {Number} timeout 
     * @param {Number} maxTries 
     * @returns {Object}
     */
    async getData(timeout = 100, maxTries = 30){
        return new Promise((resolve, reject) => {
            const self = this;
            var tryCount = 0;
            // recursive function that keeps attempting to run until
            // this.collectionPulled is true
            // like lemmings to a cliffside, the timeouts keep trying and
            // dying until it's true
            var waitForData = function(){
                if(self.collectionPulled){
                    resolve(self.data);
                } else if(tryCount > maxTries) {
                    reject('Unable to retrieve data.');
                } else {
                    setTimeout(waitForData, timeout);
                }
                tryCount++;
            }
            waitForData();
        })
    }

    /**
     * Filters the retrieved data by the filter parameters.
     * 
     * filters should be structured as:
     * `[[field, operator, value],]`
     * 
     * Operators are based off of the where() function of Firestore.
     * More information: https://firebase.google.com/docs/firestore/query-data/queries
     * @param {Array} filters 
     * @returns 
     */
    async getFilteredData(filters){
        return new Promise((resolve, reject) => {
            this.getData().then((data) => {
                var potentialItems = {};

                //this translates operator symbols to a function
                //array-contains-any and not-in are a bit more complicated and not written yet
                var operator_defs = {
                    '<': function(a,b){return a < b},
                    '<=': function(a,b){return a <= b},
                    '==': function(a,b){return a == b},
                    '>': function(a,b){return a > b},
                    '>=': function(a,b){return a > b},
                    '!=': function(a,b){return a != b},
                    'array-contains': function(a,b){return a.includes(b)},
                    'in': function(a,b){return b.includes(a)},
                }
                try {
                    for(let item of data){
                        for(let i = 0; i < filters.length; i++){
                            if(i > 0 && !potentialItems[item.id]){
                                // if we're past the initial filter, let's make sure the item
                                // was actually added before checking the next filter
                                continue;
                            }

                            let operator = filters[i][1];
                            let field = item[filters[i][0]];
                            let value = filters[i][2];

                            if(operator_defs[operator](field, value)){
                                // filter returned true, let's add the item to potentialItems
                                potentialItems[item.id] = item
                            } else if(i > 0){
                                // filter wasn't met, and we're past the initial run, so let's remove this item from the
                                // potential items, (will then continue on other filters)
                                delete potentialItems[item.id];
                            }
                        }
                    }
                    var returnObj = [];
                    // convert back to a list before return
                    for(let item in potentialItems){
                        returnObj.push(potentialItems[item]);
                    }
                    if(returnObj.length >= 1){
                        resolve({success: true, data: returnObj, message: 'Retrieved matching document(s).'});
                    } else {
                        resolve({success: false, data: [], message: 'No matching documents found.'});
                    }
                } catch(e) {
                    reject({success: false, error: e, message: 'An error occurred when attempting to query documents.'});
                }
            }).catch((e) => {
                console.error(e);
            });
        })
    }
}

export { Projects };