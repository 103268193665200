<script setup>
import { ref, defineProps, watch, defineEmits } from 'vue';

/**
 * This component expects the data prop to exist in the following format:
 * [{},]
 */

/**
 * Props the component uses.
 * 
 * - `data`: List of projects to display in modal.
 * - `open`: Boolean from parent for whether to show modal or not.
 * - `title`: Title of modal.
 */
const props = defineProps(['data', 'open', 'title'])

/**
 * Emits the component uses.
 * 
 * - `close`: Tells parent to close modal.
 */
const emit = defineEmits(['close'])

/**
 * Boolean used to show/hide modal.
 */
const showModal = ref(false);

/**
 * List of projects to display in modal (updated through watcher).
 */
const projects = ref(null);

/**
 * Toggles the display of modal.
 */
function showHideModal(){
    if(showModal.value){
        showModal.value = false;
        emit('close');
    } else {
        showModal.value = true;
    }
}

// watch props for changes and update component accordingly
watch(props, async (newProps) => {
    // check if open is true or false
    if(newProps.open){
        showModal.value = true;
    } else {
        showModal.value = false;
    }

    projects.value = newProps.data

});

</script>

<template>
    <div class="project-modal" v-if="showModal">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h2 class="modal-title h3 p-0 m-0">{{ title }}</h2>
                    <button type="button" class="close" aria-label="Close" @click="showHideModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pb-0">
                    <div v-for="project in projects" v-bind:key="project.id" :class="projects.length > 1 ? 'row' : 'row mb-3'">
                        <div v-if="project.photo != undefined" class="col col-12 col-sm-3 mb-3 mb-sm-0">
                            <img v-if="project.photo != undefined" :src="'/images/' + project.photo" :alt="project.AwardeeName + ' headshot'" />
                        </div>
                        <div :class="project.photo != undefined ? 'col col-12 col-sm-9' : 'col col-12'">
                            <h3 class="h4 mt-0">{{ project.Title }}</h3>
                            <p class="mb-0">Awardee: {{ project.AwardeeName }}</p>
                            <p class="mb-0">Location: {{ project.Location }}</p>
                            <p class="mb-0">Department: {{ project.AffiliatedDepartment }}</p>
                            <p class="mb-0">Year: {{ project.Year }}</p>
                            <a v-if="project.Link" :href="project.Link" target="_blank" rel="noopener noreferrer" class="btn btn-outline-red mt-3">More Information</a>
                        </div>
                        <div class="col col-12" v-if="projects.length > 1">
                            <hr />
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-blue" @click="showHideModal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.project-modal {
    z-index: 1001;
    background-color: rgb(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 100vh;
    max-width: 100vw;
    box-sizing: border-box;
}

img { 
    width: 100%;
}
</style>