<!--
Author: Lily McMullen
Description: This component displays a map with markers (src/assets/pin-icon) indicating project locations 
             within a specified (in the router) country.
-->

<template>
  <div class="map-container">
    <div id="map" ref="mapContainer"></div>
  </div>
</template>

<script>
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import pinIcon from '../assets/pin-icon.png'; // custom pin icon for markers

const map = [];

export default {
  props: ['country'],
  data() {
    return {
      map: null, // leaflet map instance
      markers: [] // array to store marker references
    };
  },
  mounted() {
    this.initializeMap(); // Set up the map
  },
  methods: {
    initializeMap() {
      if(map[0]){
        // removes the map instance (if active), and removes it from the map const variable
        // will reinitialize with the rest of the script
        map[0].remove();
        map.pop();
      }

      map.push(L.map(this.$refs.mapContainer).setView([0, 0], 2));
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(map[0]);
    },
    addMarkers(projects) {
      // Make sure map is set up before adding markers
      if (!map[0]) {
        console.error('Map is not initialized');
        return;
      }

      // Set custom icon for the map markers
      const customIcon = L.icon({
        iconUrl: pinIcon,
        iconSize: [38, 38], // icon size
        iconAnchor: [19, 38], // anchor point of the icon
        popupAnchor: [0, -38] // where the popup should open
      });

      const bounds = L.latLngBounds(); // create bounds for fitting the map

      // Clear existing markers if any
      this.markers.forEach(marker => map[0].removeLayer(marker));
      this.markers = [];
      // Iterate over the projects to add markers
      for(let project_id in projects){
        let project = projects[project_id];
        if(project.points){
          project.points.forEach(point => {
            if (point.latlon && point.latlon.length === 2 && (point.latlon[0] != null || point.latlon[1] != null)) {
              const latLng = L.latLng(point.latlon[0], point.latlon[1]); // create latLng for each point
              const marker = L.marker(latLng, { icon: customIcon, projectId: project.id }).addTo(map[0]);
              const popupContent = `<strong>${project.title}</strong><br><a href="#${project.id}">Read more</a>`;
              marker.bindPopup(popupContent);
              marker.on('click', () => this.$emit('highlight', project.id));
              marker.getPopup().on('remove', () => this.$emit('highlight', null));
              this.markers.push(marker);
              bounds.extend(latLng); // extend bounds to include this point
            } else {
              console.warn('Bad latlon for point:', point);
            }
          });
        } else {
          console.warn('No points found for project:', project.title);
        }
      }

      if (this.markers.length > 0) {
        map[0].setMaxZoom(6);
        map[0].fitBounds(bounds, { padding: [70, 70] });
      } else {
        console.warn('No markers added');
      }
    }
  }
};
</script>

<style scoped>
.map-container {
  width: 100%;
  height: 500px;
}

#map {
  min-height: 500px;
}
</style>
